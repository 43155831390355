$(document).ready(function () {
    $('.js-carousel-clients > *').each(function () {
        let carousel = $(this).slick({
            infinite: true,
            autoplay: true,
            arrows: false,
            dots: false,
            slidesToShow: 6,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    },
                },
            ],
        });
    });

    $('.js-carousel-quotes > *').each(function () {
        let carousel = $(this).slick({
            infinite: true,
            autoplay: true,
            arrows: false,
            dots: false,
            slidesToShow: 1
        });

        let carouselWrapper = $(this).parents(".c-carousel__wrapper");
        let carouselPrev = carouselWrapper.find("[data-prev]");
        let carouselNext = carouselWrapper.find("[data-next]");

        carouselPrev.on('click', function() {
            carousel.slick('slickPrev');
        });

        carouselNext.on('click', function() {
            carousel.slick('slickNext');
        });
    });

    $('.c-nav-mobile__button').on('click', function (event) {
        event.preventDefault();
        $(this).toggleClass('is-active');
        $('.c-nav-mobile').toggleClass('is-active');
    });
});